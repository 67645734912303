<template>
  <div>
    <AddNewCategory
      :is-add-new-category-sidebar-active.sync="isAddNewCategorySideBar"
      :dataProp="dataCategory"
      :isLoading="isLoading"
      :isUpdate="isUpdate"
      @refetch-data="refreshTable"
      @onAdd="handleCreateBrand"
      @onUpdate="handleUpdateBrand"
      title="thương hiệu"
      placeholder="Nhập tên thương hiệu"
      type="brand"
    />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col cols="12">
            <div class="d-flex align-items-center justify-between">
              <div class="flex items-center">
                <span style="width: 130px">Tìm kiếm:</span>
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Tìm kiếm..."
                />
              </div>
              <b-button variant="primary" @click="isAddNewCategorySideBar = true, isUpdate = false">
                <span class="text-nowrap">Thêm thương hiệu</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-overlay
        :show="showTable"
        rounded="sm"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
      >
        <b-table
          ref="refCategoriesTable"
          class="position-relative"
          :items="fetchBrand"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :busy="isLoading"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Không có dữ liệu hiển thị"
          :sort-desc.sync="isSortDirDesc"
        >
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle mb-1"></b-spinner>
              <br />
              <strong>Loading...</strong>
            </div>
          </template>
          <!-- Column: Title -->
          <template #cell(title)="data">
            <b-media vertical-align="center">
              <b-link
                class="font-weight-bold d-block text-nowrap"
                @click="showEditBar(data.item)"
              >{{ data.item.title }}</b-link>
            </b-media>
          </template>

          <!-- Column: createdAt -->
          <template #cell(created_at)="data">
            <span class="text-capitalize">{{ formatDate(data.item.created_at) }}</span>
          </template>
          <!-- Column: updatedAt -->
          <template #cell(updatedAt)="data">
            <span class="text-capitalize">{{ formatDate(data.item.updatedAt) }}</span>
          </template>
          <!-- Column: Actions -->
          <template #cell(action)="data">
            <div class="d-flex justify-content-end">
              <feather-icon
                icon="EditIcon"
                size="22"
                class="text-primary cursor-pointer"
                @click="showEditBar(data.item)"
              />
              <!-- <feather-icon
                icon="Trash2Icon"
                size="22"
                class="text-danger ml-2 cursor-pointer"
                @click="deleteRate(data.item.id)"
              />-->
            </div>
          </template>
        </b-table>
      </b-overlay>

      <div class="mx-2 mb-2">
        <b-row class="flex items-center justify-between">
          <b-col
            cols="12"
            md="4"
            class="d-flex flex-1 align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Hiển thị {{ dataMeta.from }} đến {{ dataMeta.to }} của {{ dataMeta.of }}
              mục
            </span>
          </b-col>
          <!-- Per Page -->
          <div cols="12" sm="8" class="flex items-center justify-end flex-1">
            <div>
              <label>Hiển thị</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="[10, 25, 50]"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>mục</label>
            </div>
            <!-- Pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="totalCategories"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0 mr-1 ml-2"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AddNewCategory from './components/add-new-form.vue'
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BPagination,
  BOverlay
} from 'bootstrap-vue'
import moment from 'moment'
import vSelect from 'vue-select'

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BPagination,
    vSelect,
    AddNewCategory
  },
  setup() {
    const listBrands = ref([])
    const isUpdate = ref(false)
    const isSortDirDesc = ref(false)
    const sortBy = ref('')
    const toast = useToast()
    const showTable = ref(false)
    const refCategoriesTable = ref(null)
    const dataCategory = ref({})
    const isAddNewCategorySideBar = ref(false)
    const isUpdateCategorySidebarActive = ref(false)
    const currentPage = ref(1)
    const isLoading = ref(false)
    const perPage = ref(10)
    const searchQuery = ref('')
    const timeout = ref(null)
    const totalCategories = ref(0)
    const tableColumns = [
      {
        key: 'name',
        label: 'Tên thương hiệu',
        sortable: false
      },
      { key: 'address', label: 'Địa chỉ', sortable: false },
      // { key: 'creator', label: 'Người tạo', sortable: false },
      { key: 'rep_name', label: 'Người đại diện', sortable: false },
      {
        key: 'created_at',
        label: 'Ngày tạo',
        class: 'text-right',
        sortable: false
      },
      {
        key: 'action',
        label: '',
        sortable: false,
        class: 'text-right'
      }
    ]

    watch([currentPage, perPage, searchQuery], () => {
      showTable.value = true
      clearTimeout(timeout.value)
      timeout.value = setTimeout(() => {
        refetchData()
        showTable.value = false
      }, 1000)
    })

    const showEditBar = data => {
      dataCategory.value = {
        name: data.name,
        contact_name: data.rep_name,
        id: data.id,
        address: data.address,
        phone_number: data.rep_phone,
        description: data.description,
        email: data.rep_email
      }
      isAddNewCategorySideBar.value = true
      isUpdate.value = true
    }

    const formatDate = time => {
      return moment(time).format('HH:mm:ss DD/MM/YYYY')
    }

    // const deleteRate = id => {
    //   // this.$bvModal
    //   //   .msgBoxConfirm('Bạn có chắc chắn muốn xoá Đơn vị này không?', {
    //   //     title: 'Xoá Đơn vị',
    //   //     size: 'sm',
    //   //     okVariant: 'primary',
    //   //     okTitle: 'Xác nhận',
    //   //     cancelTitle: 'Huỷ',
    //   //     cancelVariant: 'outline-secondary',
    //   //     hideHeaderClose: false,
    //   //     centered: true
    //   //   })
    //   //   .then(value => {
    //   //     if (value) {
    //   //       this.deleteProductCategory(id)
    //   //     }
    //   //   })
    // }

    const handleCreateBrand = async data => {
      const isSuccess = await createBrand(data)
      if (isSuccess) {
        isAddNewCategorySideBar.value = false
        refetchData()
      }
    }

    const handleUpdateBrand = async data => {
      const isSuccess = await updateBrand(data)
      if (isSuccess) {
        isUpdate.value = false
        isAddNewCategorySideBar.value = false
        refetchData()
      }
    }

    const refreshTable = () => {
      showTable.value = true
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        refetchData()
        showTable.value = false
      }, 2000)
    }

    const fetchBrand = (ctx, callback) => {
      isLoading.value = true
      store
        .dispatch('configProductPartner/fetchBrand', {
          keyword: searchQuery.value,
          page_size: perPage.value,
          page_num: currentPage.value,
          supplier_id: store.state.authenticate.userLogin.supplierInfo.id,
          sort_by: 'id',
          order: 'desc'
        })
        .then(response => {
          isLoading.value = false
          listBrands.value = response.data?.data || []
          totalCategories.value = response.data?.page?.total
          callback(listBrands.value)
        })
        .catch(e => {
          isLoading.value = false
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Có lỗi xảy ra khi lấy dữ liệu',
              icon: 'AlertTriangleIcon',
              variant: 'danger'
            }
          })
        })
    }

    const createBrand = async data => {
      try {
        isLoading.value = true
        return await store
          .dispatch('configProductPartner/createBrand', {
            name: data.name,
            rep_name: data.contact_name,
            rep_phone: data.phone_number,
            address: data.address,
            description: data.description,
            rep_email: data.email,
            supplier_id: store.state.authenticate.userLogin.supplierInfo.id
          })
          .then(response => {
            isLoading.value = false

            toast({
              component: ToastificationContent,
              props: {
                title: 'Thêm thương hiệu thành công',
                icon: 'CheckCircleIcon',
                variant: 'success'
              }
            })

            return true
          })
      } catch (error) {
        isLoading.value = false
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Có lỗi xảy ra khi thêm thương hiệu',
            icon: 'AlertTriangleIcon',
            variant: 'danger'
          }
        })
        return false
      } finally {
        isLoading.value = false
      }
    }

    const updateBrand = async value => {
      try {
        isLoading.value = true
        const data = {
          name: value.name,
          rep_name: value.contact_name,
          address: value.address,
          description: value.description,
          rep_email: value.email,
          rep_phone: value.phone_number
        }
        return await store
          .dispatch(`configProductPartner/updateBrand`, {
            id: value.id,
            data: data
          })
          .then(response => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Cập nhật thương hiệu thành công',
                icon: 'CheckCircleIcon',
                variant: 'success'
              }
            })

            return true
          })
      } catch (error) {
        console.log(error)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Có lỗi xảy ra khi cập nhật thương hiệu',
            icon: 'AlertTriangleIcon',
            variant: 'danger'
          }
        })
        return false
      } finally {
        isLoading.value = false
      }
    }

    const dataMeta = computed(() => {
      const localItemsCount = refCategoriesTable.value
        ? refCategoriesTable.value.localItems.length
        : 0
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalCategories.value
      }
    })

    const refetchData = () => {
      refCategoriesTable.value.refresh()
    }

    return {
      isSortDirDesc,
      sortBy,
      showTable,
      perPage,
      currentPage,
      refCategoriesTable,
      searchQuery,
      dataCategory,
      isUpdateCategorySidebarActive,
      isAddNewCategorySideBar,
      isLoading,
      tableColumns,
      fetchBrand,
      totalCategories,
      dataMeta,
      refetchData,
      store,
      toast,
      isUpdate,
      ToastificationContent,
      createBrand,
      listBrands,
      updateBrand,
      refreshTable,
      handleUpdateBrand,
      handleCreateBrand,
      // deleteRate,
      formatDate,
      showEditBar
    }
  }
}
</script>

<style>
</style>